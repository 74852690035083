import { MAINTAIN_PERMISSION, GROWERS_CONTRACTS } from "@kraftheinz/common";
import { n as normalizeComponent } from "./vueComponentNormalizer.js";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("list-composer", {
    attrs: {
      "title": "Contracts",
      "search-by": "Code,GrowerCode,GrowerName,PricingCropCode",
      "ph-search-by": "Search by Contract Code, Grower Code, Grower Name and Pricing Crop Code"
    },
    scopedSlots: _vm._u([{
      key: "action",
      fn: function(_ref) {
        var edit = _ref.edit, record = _ref.record;
        return [_vm.hasMaintainPermission ? _c("a-tooltip", {
          scopedSlots: _vm._u([{
            key: "title",
            fn: function() {
              return [_vm._v("Edit")];
            },
            proxy: true
          }], null, true)
        }, [_c("a-button", {
          attrs: {
            "disabled": record.isInactive,
            "icon": "edit",
            "size": "small",
            "type": "link"
          },
          on: {
            "click": function($event) {
              return edit(record);
            }
          }
        })], 1) : _vm._e()];
      }
    }])
  }, [_c("text-field", {
    key: "code",
    attrs: {
      "data-index": "code",
      "title": "Contract Code"
    }
  }), _c("text-field", {
    key: "GrowerCode",
    attrs: {
      "data-index": "growerCode",
      "title": "Grower Code"
    }
  }), _c("text-field", {
    key: "GrowerName",
    attrs: {
      "data-index": "growerName",
      "title": "Grower Name"
    }
  }), _c("text-field", {
    key: "PricingCropCode",
    attrs: {
      "data-index": "pricingCropCode",
      "title": "Pricing Crop Code"
    }
  }), _c("icon-field", {
    key: "EmailCertificates",
    attrs: {
      "data-index": "emailCertificates",
      "title": "Email Certificates",
      "align": "center",
      "render-icon": _vm.renderIcon
    }
  }), _c("icon-field", {
    key: "EmailPaddock",
    attrs: {
      "data-index": "emailPaddock",
      "title": "Email Paddock",
      "align": "center",
      "render-icon": _vm.renderIcon
    }
  }), _c("text-field", {
    key: "EmailAddress",
    attrs: {
      "data-index": "emailAddress",
      "title": "Email Address"
    }
  })], 1);
};
var staticRenderFns$1 = [];
const __vue2_script$1 = {
  name: "ListContracts",
  inject: ["can"],
  computed: {
    hasMaintainPermission() {
      return this.can(MAINTAIN_PERMISSION);
    }
  },
  methods: {
    renderIcon(value) {
      if (!value) {
        return "";
      }
      return "check-circle";
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var ListContracts = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", {
    staticClass: "list-contracts"
  }, [_c("bread-crumb", {
    attrs: {
      "items": _vm.itemsMenu
    }
  }), _c("resource", {
    attrs: {
      "name": "grower.contracts",
      "api-url": _vm.apiUrl,
      "filter-route": "/watties-grower/contracts/advanced-search",
      "edit-route": "/watties-grower/contracts/:id",
      "list": _vm.ListContracts,
      "page": _vm.page
    }
  }), _c("router-view")], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  name: "Contracts",
  data() {
    return {
      ListContracts,
      apiUrl,
      page: GROWERS_CONTRACTS,
      itemsMenu: [
        {
          key: "masterfiles",
          title: "Master Files",
          path: ""
        },
        {
          key: "contracts",
          title: "Contracts",
          path: "/watties-grower/contracts"
        }
      ]
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
